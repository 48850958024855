<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import SecondaryButton from "@/components/SecondaryButton.vue";

export default {
	props: {
		userInput: {
			type: Object,
			required: false,
		},
	},
	components: {
		SecondaryButton,
	},
	data() {
		return {
			listed: false,
			action: "Agregar",
			user: {
				id: { name: "id", value: null },
				photo: { name: "photo", value: null },
				code: { name: "Número de Empleado", value: null },
				name: { name: "Nombre", value: null },
				work_position: {
					name: "Puesto",
					value: null,
				},
				role_id: {
					name: "Rol",
					value: null,
					type: "select",
					options: {},
				},
				territory_id: {
					name: "Territorio",
					value: null,
					type: "select",
					options: {},
				},
				region_id: {
					name: "Región",
					value: null,
					type: "select",
					options: {},
				},
				agency_id: {
					name: "Agencia",
					value: null,
					type: "select",
					options: {},
				},
			},
		};
	},
	created() {
		this.user.id.value = this.userInput.id;

		if (this.user.id.value) {
			this.action = "Actualizar";
			this.loadUser();
		} else {
			this.loadFilter();
		}
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
			//READ_USER
			readUser: "getUsers/readUsers",

			//READ_FILTERS
			readTerritories: "getTerritories/readTerritories",
			readRegions: "regionsStore/readRegions",
			readAgencies: "agenciesStore/readAgencies",
			readRoles: "getRoles/readRoles",

			//READ_ERRORS
			loadUserError: "getUsers/readError",
			createError: "createUser/readError",
			updateError: "updateUser/readError",
			deleteError: "deleteUser/readError",
			territoriesError: "getTerritories/readError",
			regionsError: "regionsStore/readError",
			agenciesError: "agenciesStore/readError",
			rolesError: "getRoles/readError",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",

			//SET_FILTERS_ID
			setRegionsFilter: "regionsStore/SET_FILTER",
			setRegionId: "regionsStore/SET_ID",
			setAgenciesFilter: "agenciesStore/SET_FILTER",
			setAgencyId: "agenciesStore/SET_ID",

			//SET_USER
			setId: "getUsers/SET_ID",
			setUser: "createUser/SET_USER",
			setUpdateId: "updateUser/SET_ID",
			setUpdateUser: "updateUser/SET_USER",
			setDeleteId: "deleteUser/SET_ID",

			//SET_ERRORS
			setLoadError: "getUsers/SET_ERROR",
			setCreateError: "createUser/SET_ERROR",
			setUpdateError: "updateUser/SET_ERROR",
			setDeleteError: "deleteUser/SET_ERROR",
			setRolesError: "getRoles/SET_ERROR",
		}),
		...mapActions({
			//GET_FILTERS
			getTerritories: "getTerritories/getTerritories",
			regionActions: "regionsStore/regionActions",
			agencyActions: "agenciesStore/agencyActions",
			getRoles: "getRoles/getRoles",

			//GET_USER
			createUser: "createUser/createUser",
			getUser: "getUsers/getUsers",
			deleteUser: "deleteUser/deleteUser",
			updateUser: "updateUser/updateUser",
		}),
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		loadUser() {
			this.loadFilter();
		},
		loadFilter() {
			this.clearErrors();
			this.setLoading(true);
			this.clearFilter("territory_id");
			this.clearFilter("region_id");
			this.clearFilter("agency_id");
			this.clearFilter("role_id");

			this.getRoles().then((response) => {
				if (response) {
					if (this.readRoles.length > 0) {
						for (let r in this.readRoles) {
							this.$set(
								this.user.role_id.options,
								[this.readRoles[r].id],
								this.readRoles[r].title
							);
						}
					}
				}
			});

			this.getTerritories()
				.then((response) => {
					if (response) {
						if (this.readTerritories.length > 0) {
							for (let t in this.readTerritories) {
								this.$set(
									this.user.territory_id.options,
									[this.readTerritories[t].id],
									this.readTerritories[t].name
								);
							}
							if (this.action == "Actualizar") {
								this.refreshFilter("territory_id");
							}
						}
					}
				})
				.finally(() => {
					this.setLoading(false);
				});
		},
		refreshFilter(selected) {
			this.clearErrors();
			this.setLoading(true);

			let filter =
				this.action == "Agregar" || this.listed
					? this.user[selected].value
					: this.userInput[selected];
			if (this.action == "Actualizar" && !filter && !this.listed)
				this.mountUser();

			if (selected == "territory_id") {
				this.clearFilter("region_id");
				this.clearFilter("agency_id");

				//REGIONS
				this.setRegionId(null);
				this.setRegionsFilter(filter);
				this.regionActions("getRegions")
					.then((response) => {
						if (response) {
							if (this.readRegions.length > 0) {
								for (let r in this.readRegions) {
									this.$set(
										this.user.region_id.options,
										[this.readRegions[r].id],
										this.readRegions[r].name
									);
								}
							}
							if (this.action == "Actualizar") {
								this.refreshFilter("region_id");
							}
						}
					})
					.finally(() => {
						this.setLoading(false);
					});
			} else if (selected == "region_id") {
				this.clearFilter("agency_id");

				//AGENCIES
				this.setAgencyId(null);
				this.setAgenciesFilter(filter);
				this.agencyActions("getAgencies")
					.then((response) => {
						if (response) {
							if (this.readAgencies.length > 0) {
								for (let a in this.readAgencies) {
									this.$set(
										this.user.agency_id.options,
										[this.readAgencies[a].id],
										this.readAgencies[a].name
									);
								}
							}
							if (!this.listed && this.action == "Actualizar") {
								this.mountUser();
							}
						}
					})
					.finally(() => {
						this.setLoading(false);
					});
			} else {
				//WAIT FOR SUBMIT EVENT
				this.setLoading(false);
			}
		},
		mountUser() {
			for (let u in this.user) {
				this.$set(this.user[u], ["value"], this.userInput[u]);
			}
			this.listed = true;
		},
		remove() {
			this.clearErrors();

			this.$swal({
				title: "¿Desea eliminar este Colaborador?",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				confirmButtonText: "Aceptar",
			}).then((result) => {
				if (result.isConfirmed) {
					this.$emit("remove", this.userInput);
				}
			});
		},
		submit() {
			this.clearErrors();
			this.setLoading(true);
			let user = this.user;
			let output = {};

			for (let u in user) {
				output[u] = user[u].value;
			}

			if (this.action == "Agregar") {
				this.setUser(output);
				this.createUser().then((result) => {
					if (result) {
						this.$swal({
							icon: "success",
							title: `El Colaborador ${output.name} ha sido creado con éxito.`,
							showConfirmButton: false,
							timer: 3000,
						});
						this.toggleForm();
					}
				});
			} else {
				this.setUpdateId(output.id);
				this.setUpdateUser(output);

				this.updateUser().then((result) => {
					if (result) {
						this.loadUser();
						this.$swal({
							icon: "success",
							title: `El Colaborador ${output.name} se actualizó con éxito.`,
							showConfirmButton: false,
							timer: 3000,
						});
						this.toggleForm();
					}
				});
			}
			this.setLoading(false);
		},
		toggleForm() {
			this.$emit("toggleForm");
		},
		clearFilter(filter) {
			this.$set(this.user[filter], ["value"], null);
			this.$set(this.user[filter], ["options"], {});
		},
		clearErrors() {
			this.setLoadError(null);
			this.setCreateError(null);
			this.setDeleteError(null);
			this.setUpdateError(null);
			this.setRolesError(null);
		},
	},
};
</script>

<template>
	<div
		class="modal-form fixed right-0 top-0 p-3 md:p-5 w-full h-full z-30 overflow-y-auto"
	>
		<div
			class="content-wrap bg-white border-2 border-gray px-6 pt-10 pb-6 shadow-md absolute max-w-3xl mx-auto right-0 left-0"
		>
			<secondary-button
				text="x"
				@click.native="toggleForm"
				buttonColor="bg-red"
				class="close rounded-full h-10 absolute right-6 top-6"
			/>

			<div class="md:flex items-center justify w-full px-3 mb-10">
				<span
					class="block mx-auto mb-6 md:mb-0 bg-gray-light flex items-center justify-center flex-none overflow-hidden"
					:class="user.photo.value ? 'w-32 h-32' : 'p-10'"
				>
					<font-awesome-icon
						v-if="!user.photo.value"
						class="w-20 h-20 block green"
						icon="fa-solid fa-user"
					/>
					<img
						v-else
						class="block w-full h-full object-cover transform rotate-90"
						:src="user.photo.value"
						:alt="
							!user.name || user.basic_info
								? `${user.first_name} ${user.last_name}`
								: user.name
						"
					/>
				</span>
				<div class="mx-auto">
					<h2 class="w-full flex-none text-center text-2xl mb-6">
						{{ action == "Agregar" ? "Crear" : action }} Colaborador
					</h2>
					<p v-if="action == 'Actualizar'" class="text-center">
						No. Empleado
						<span class="b green"> {{ user.code.value }}</span>
					</p>
				</div>
			</div>
			<!-- DISPLAY FORM -->
			<form class="grid grid-cols-2 gap-5 items-center">
				<label
					v-for="(d, key) in user"
					:key="d.id"
					v-show="
						key != 'id' &&
						key != 'photo' &&
						(key != 'code' || action == 'Agregar')
					"
					class="col-span-2 text-lg"
					:class="{
						'md:col-span-1 m-0': d.type == 'select',
					}"
					:for="key"
					><span class="block">{{ d.name }}</span>
					<textarea
						v-model="d.value"
						class="w-full border border-gray h-full px-4 py-2 text-lg rounded-md bg-gray-light align-top"
						:type="
							d.type == 'number'
								? 'number'
								: key == 'email'
								? 'email'
								: key == 'password'
								? 'password'
								: d.type == 'check'
								? 'checkbox'
								: 'text'
						"
						:name="key"
						:id="key"
						:placeholder="
							key == 'id' ? 'Generado automáticamente' : d.name
						"
						v-if="d.type != 'select'"
						:required="key == 'code'"
						:disabled="d.name == 'id'"
						rows="2"
						v-capitalizeInput
					/>
					<select
						@change="refreshFilter(key)"
						class="w-full h-12 mt-2 px-4 py-2 text-lg bg-gray-light rounded-md"
						:name="d.name"
						:id="key"
						v-if="d.type == 'select'"
						v-model="d.value"
					>
						<option
							v-for="(option, index) in d.options"
							:key="index"
							:value="index"
							v-capitalizeWords="option"
						></option>
					</select>
				</label>
				<!-- DISPLAY_ERRORS -->
				<span
					v-if="loadUserError"
					class="w-full block mb-3 red text-center b"
					>{{ loadUserError }}</span
				>
				<span
					v-if="createError"
					class="w-full block mb-3 red text-center b"
					>{{ createError }}</span
				>
				<span
					v-if="deleteError"
					class="w-full block mb-3 red text-center b"
					>{{ deleteError }}</span
				>
				<span
					v-if="updateError"
					class="w-full block mb-3 red text-center b"
					>{{ updateError }}</span
				>
				<span
					v-if="territoriesError"
					class="w-full block mb-3 red text-center b"
					>{{ territoriesError }}</span
				>
				<span
					v-if="regionsError"
					class="w-full block mb-3 red text-center b"
					>{{ regionsError }}</span
				>
				<span
					v-if="agenciesError"
					class="w-full block mb-3 red text-center b"
					>{{ agenciesError }}</span
				>
				<!-- DISPLAY BUTTONS -->
				<div
					class="col-span-2 my-6 flex flex-col items-center md:flex-row justify-evenly"
				>
					<SecondaryButton
						v-if="action == 'Actualizar'"
						@click.native="remove()"
						text="Eliminar"
						buttonColor="bg-red"
						class="flex-none mb-3 md:mb-0 md:mr-2"
					/>
					<SecondaryButton
						@click.native="toggleForm()"
						v-else
						text="Cancelar"
						buttonColor="bg-red"
						class="flex-none mb-3 md:mb-0 md:mr-2"
					/>
					<SecondaryButton
						:text="action"
						@click.native="submit"
						class="flex-none"
					/>
				</div>
			</form>
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
